import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "./BlogRoll.js";
import FullWidthImage from "../components/FullWidthImage";

const Dealerscard = (props) => {
  return (
    <div class="col-sm-6 col-lg-3 mt-2">
      <div class="card border-dark h-100">
        <div class="card-header border-dark bg-dark text-white">          
          <h5 class="card-title">{props.name}</h5>
          </div>
        <div class="card-body border-dark justify-content-evenly">
        <div class="col justify-content-evenly">        
            

          <div class="row align-items-center text-center d-block blockquote bg-light">{props.region}</div>
          
          <div class="row align-items-center text-center d-block blockquote pb-1">{props.text}<br />{props.text2}</div> 

          <div class="row align-items-center text-center d-block pb-1 bg-light blockquote">{props.phone}</div> 

          <div class="row align-items-end text-center d-block">{props.mail}</div> 

            </div>          
        </div>
      </div>
    </div>
  )
}

export default Dealerscard