import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Dealerscard from "../../components/Dealerscard"

const ContactPage = () => (
  <Layout>
    <Container>
      <div className="column">
        <h1 class="text-center">КОНТАКТЫ</h1>
        <div>
          <h2 class="text-center">Общество с ограниченной ответственностью "ОЗДОКЕН&nbsp;АГРО"</h2>
          <p class="text-center">Адрес: Россия, 410536, Саратовская обл., Саратовский р-н, п. Зоринский, Промузел Зоринский промзона, ком. 21 <br />
            e-mail: <a href="mailto:ozdoken.agro@yandex.ru">ozdoken.agro@yandex.ru</a></p>
        </div>

        <div class="row justify-content-center text-center">

          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card h-100">
              <div class="card-header"><h5 class="card-title">Директор</h5></div>
              <div class="card-body">
                <p class="card-title">Михайловский Дмитрий Виниаминович<br />
                  Телефон: <strong>8 (967) 800-20-20</strong></p>
              </div>
            </div>
          </div>

          {/* <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card h-100">
              <div class="card-header "><h5 class="card-title">Руководитель отдела продаж</h5></div>
              <div class="card-body">
                <p class="card-title">Попов Артем Андреевич<br />
                  Телефон: <strong>8 (937) 976-46-56</strong></p>
              </div>
            </div>
          </div> */}

          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card  h-100">
              <div class="card-header "><h5 class="card-title">Отдел продаж</h5></div>
              <div class="card-body  ">
                <p class="card-title">Сидорков Дмитрий Сергеевич<br />
                  Телефон: <strong>8 (937) 976-46-55</strong></p>
                <p class="card-title">Подколодная Вероника Евгеньевна<br />
                  Телефон: <strong>8 (927) 112-97-73</strong></p>

              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card  h-100">
              <div class="card-header  "><h5 class="card-title">Отдел Запчастей</h5></div>
              <div class="card-body  ">
                <p class="card-title">Нижников Александр Владимирович<br />
                  Телефон: <strong>8 (927) 112-97-16</strong></p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card   h-100">
              <div class="card-header  "><h5 class="card-title">Сервис</h5></div>
              <div class="card-body  ">
                <p class="card-title">Атапин Алексей Владимирович<br />
                  Телефон: <strong>8 (927) 112-97-38</strong></p>
              </div>
            </div>
          </div>

          
          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card   h-100">
              <div class="card-header  "><h5 class="card-title">Региональный  представитель в ЮФО, СКФО, ЦФО</h5></div>
              <div class="card-body  ">
                <p class="card-title">Юрьев Александр  Викторович<br />
                  Телефон: <strong>8 (938) 122-80-08</strong><br />e-mail: <a href="mailto:ozdoken-yug@yandex.ru">ozdoken-yug@yandex.ru</a></p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 mt-2">
            <div class="card   h-100">
              <div class="card-header  "><h5 class="card-title">Представитель Фирмы-производителя Ozdoken A.S., Турция</h5></div>
              <div class="card-body  ">
                <p class="card-title">Ассистент по внешней торговле
                  <br />Арслан Аннагылыджов
                  Телефон в Турции: <strong>+90 552 582 10 93</strong><br />e-mail: <a href="mailto:salesru@ozdoken.com.tr">salesru@ozdoken.com.tr</a></p>
              </div>
            </div>
          </div>

        </div>








        <h2 class="text-center">ДИЛЕРЫ</h2>

        <div class="row justify-content-center text-center">
          <Dealerscard name="ООО «Агросервис»" region="Ростов, центральный офис" text="Гончаров" text2="Олег Васильевич" phone="8-800-500-818-0" mail="info@oooas.pro" />
          <Dealerscard name="ООО «Агросервис»" region="Ростов, офис в г. Новошахтинске" text="Выдрич" text2="Дмитрий Олегович" phone="8 (961) 428-11-84"  />
          <Dealerscard name="ООО «Агросервис»" region="Ростов, филиал Восток" text="Алексеенко" text2="Вадим Алексеевич" phone="8-800-600-81-82 доб. 107, 8-951-499-55-61 (моб.)" mail="Тацинский р-н, примерно 4 км от ст. Тацинская, по направлению на север" />
          <Dealerscard name="ООО «Агросервис»" region="Ростов, филиал Юг" text="Сердюков" text2="Леонид Владимирович" phone="8-800-600-81-82,      8-900-124-53-81 (моб.)" mail="347661, Ростовская область, Егорлыкский район, станица Егорлыкская, ул. Белозерцева, 124-г" />
          {/* <Dealerscard name="ООО «Ист Машинери» " region="Московская область, Тверская область" text="Курганов " text2="Дмитрий Валерьевич" phone="+7 (965) 264-48-54" mail="d.kurganov@eastmach.ru" />
          <Dealerscard name="ООО «Ист Машинери»" region="Белгородская область, Брянская область, Курская область, Орловская область, Тульская область, Смоленская область, Калужская область, Владимирская область" text="Тимошенко " text2="Наталья Алексеена" phone="+7 (968) 824-90-58" mail="" /> */}
          <Dealerscard name="ООО «Спецагротех»" region="Оренбургская область" text="Пасечный " text2="Валерий Николаевич" phone="+7 (987) 847-08-00" mail="specagroteh56@mail.ru" />
          <Dealerscard name="ООО «Агротехника»" region="Пензенская область" text="Кондранов " text2="Алексей Валерьевич" phone="+7 (903) 323-36-68" mail="agrosoyz58@yandex.ru" />
          <Dealerscard name="ООО «Агротехника»" region="Мордовская область" text="Смагин " text2="Артем Сергеевич" phone="+7 (965) 634-40-33" mail="" />
          <Dealerscard name="ООО «ИНТЕРХИМ»" region="Республики СКФО, Крым, Адыгея, Ставропольский , Краснодарский край" text="Чугуев" text2="Сергей Николаевич" phone="+7 (961) 280-71-71" mail="interchem2011@bk.ru" />
          <Dealerscard name="ООО «ТЕХНО-СНАБ Ульяновск» " region="Ульяновская область" text="Новиков " text2="Владислав Анатольевич" phone="+7 (905) 037-94-02" mail="" />
          <Dealerscard name="ООО «ТЕХНО-СНАБ Ульяновск» " region="Марий Эл" text="Титов " text2="Никита Сергеевич" phone="+7 (960) 378-79-50" mail="" />
          <Dealerscard name="ООО «ТЕХНО-СНАБ Ульяновск» " region="Самарская область" text="Малых " text2="Антон Анатольевич" phone="+7 (960) 373-48-26" mail="" />
          <Dealerscard name="ООО «ТЕХНО-СНАБ Ульяновск» " region="Республика Чувашия" text="Грибов " text2="Вячеслав " phone="+7 (960) 373-47-16" mail="" />
          <Dealerscard name="ООО «ТЕХНО-СНАБ Ульяновск» " region="Республика Татарстан" text="Гарей " text2="Нутфулин " phone="+7 (967) 372-21-01" mail="" />
          <Dealerscard name="АО «Агростиль»" region="Челябинская область" text="Плотников" text2="Сергей Олегович" phone="+7 900 022 23 32" mail="sp@agrostyle.pro, 454080, г. Челябинск, улица Труда, дом 197. agro-style.ru" />
          <Dealerscard name="АО «Агростиль»" region="Курганская область" text="Эмих" text2="Рудольф Константинович" phone="+7-912-312-55-21" mail="er@agrostyle.pro" />
          <Dealerscard name="АО «Агростиль»" region="Оренбургская область" text="Черкасский" text2="Павел Алексеевич" phone="8 (3532) 32-32-42, +7 932 852 05 01" mail="001@agrostyle.pro, 460050, Оренбургская область, г. Оренбург, ул. Берёзка, здание 22А, строение 3, помещение 1. agro-style.ru" />
          <Dealerscard name="ООО «Прицепное и навесное»" region="Тамбовская область" text="Шигорев " text2="Роман Сергеевич" phone="+7-915-882-31-01" mail="rss@agroparts24.ru" />
          <Dealerscard name="ООО «С/Х МАРКЕТ»" region="Тамбовская область" text="Архипов " text2="Вадим Алексеевич" phone="+7 (991) 523-19-99" mail="ava@cx-market.ru" />
          {/* <Dealerscard name="ООО «С/Х МАРКЕТ»" region="Тамбовская область" text="Максим " text2=" " phone="" mail="emv@cx-market.ru" /> */}
          <Dealerscard name="ООО «Агромашалтай»" region="Алтайский край" text="Комков " text2="Дмитрий Викторович" phone="+7 913-091-1042" mail="dvkomkov@mail.ru" />
          <Dealerscard name="ООО «ТЕХАГРО»" region="Волгоградская область" text="Станкус " text2="Наталья Сергеевна" phone="8-937-697-73-68" mail="rostehagro34@mail.ru" />
          <Dealerscard name="ИП Малеванный С. И. (РосТехАгро)" region="Волгоградская область" text="Горбунов" text2="Илья" phone="8-902-314-90-55" mail="rostehagro34@mail.ru" />
          {/* <Dealerscard name="ИП Чесноков Алексей Викторович" region="Пугачевский район" text="Чесноков " text2="Алексей Викторович" phone="+7 (929) 777-22-33" mail="chesnokav@yandex.ru" /> */}
          <Dealerscard name="ИП ОБРУГОВ М.А." region="ЧЕЧЕНСКАЯ РЕСПУБЛИКА" text="ОБРУГОВ" text2="Мустафа Шайх-Ахмедович" phone="+7 (928) 945-55-53" mail="ЧЕЧЕНСКАЯ РЕСПУБЛИКА, Г. ГУДЕРМЕС, УЛ. ЛАВИЦКОГО, 89" />
          



        </div>

        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </Container>

  </Layout>
)

export default ContactPage
